<template>
  <div>
    <div class="shape"></div>

    <v-card
      height="350px"
      class="px-5 pb-5 teamBackground center"
      tile
      elevation="0"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="9" align-self="center">
          <h1
            class="
              text-center
              teamHeader
              text-h3 text-md-h2 text-lg-h2 
              font-weight-medium
            "
          >
            Meet Our Founders
          </h1>
        </v-col>
      </v-row>
    </v-card>
    <v-row align="center" justify="center" class="mt-1 mt-sm-0">
      <v-col cols="12" sm="10" md="10" xl="10" class="pt-15">
        <v-card
          style="border: 4px solid #158f92"
          tile
          elevation="0"
          class="ma-5 ma-md-0 mb-11 mb-sm-5 team-card"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-img class="ma-sm-10" src="@/assets/ZazaPhoto.png" />
            </v-col>
            <v-col cols="12" md="6" class="px-6 px-10 pa-md-10 pl-md-0">
              <p
                class="
                  teamText
                  text-center
                  text-sm-left
                  text-h4
                  text-lg-h2
                  text-md-h3
                  text-sm-h4
                "
              >
                Zaza Soriano
              </p>
              <p class="teamSubText">
                Zaza Soriano is a full-time Senior Software Engineer at NASA.
                There she works in the EarthSciences group, solving hard
                problems and changing the world. She is the founder and CEO of
                ZaaWink Limited, and holds a double Bachelors Degree in Computer
                Engineering and Electrical Engineering from the University of
                Central Florida. She also started her own dog treat business,
                Quark E Noms. Zaza volunteers as the Systers Keeper, a leader
                for a global community of women in tech. When she is not
                working, or volunteering, Zaza likes to work on side projects
                and go on adventures with her Shikoku dog, Gluon.
              </p>
              <v-container
                class="
                  d-flex
                  justify-space-around
                  mb-4 mb-md-0
                  pt-lg-5
                  pl-lg-0
                  pr-lg-10
                "
              >
                <v-btn
                  rounded
                  dark
                  large
                  color="#238587"
                  class="px-10 px-sm-15"
                  :width="btnWidth"
                  style="font-weight: 700"
                  href="mailto:zsoriano@zaawink.com"
                  target="_blank"
                >
                  EMAIL
                </v-btn>
                <v-btn
                  rounded
                  large
                  outlined
                  class="px-5 px-sm-15"
                  :width="btnWidth"
                  style="font-weight: 700"
                  href="https://www.linkedin.com/in/shikgluon/"
                  target="_blank"
                >
                  LINKEDIN
                </v-btn>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" class="pt-sm-15">
        <v-card
          style="border: 4px solid #158f92"
          tile
          elevation="0"
          class="ma-5 ma-md-0 mb-md-15 mb-15"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="px-6 px-10 pr-md-0 pa-md-10"
              order-md="1"
              order="2"
            >
              <p
                class="
                  teamText
                  text-center
                  text-sm-left
                  text-h4
                  text-lg-h2
                  text-md-h3
                  text-sm-h4
                "
              >
                Andrea<br class="hidden-large" />
                Delgado-Olson
              </p>
              <p class="teamSubText">
                Andrea Delgado-Olson was the Senior Program Manager for Systers
                Community and Programs at AnitaB.org. She managed several
                events, programs, and initiatives that served a global community
                of technical women, and in addition, she organized and managed
                logistics and content for over 25 sessions and events at the
                organization’s annual conference, the Grace Hopper Celebration.
                <br />
                <br />
                Andrea has since founded a consulting firm, Indigenous
                Perspectives for Reconciliation Resources. She serves on the
                AISES Board of Directors where she is the Chair of the
                Professional Development Committee and Co-Chair of the Education
                Committee for K-12 outreach. Andrea also volunteers for numerous
                organizations to improve opportunities and visibility for
                underrepresented communities, women technologists, Indigenous
                people in STEM, and K-12.
              </p>
              <v-container
                class="
                  d-flex
                  justify-space-around
                  mb-4 mb-md-0
                  pt-lg-5
                  pl-lg-0
                  pr-lg-10
                "
              >
                <v-btn
                  rounded
                  dark
                  large
                  color="#238587"
                  class="px-10 px-sm-15"
                  :width="btnWidth"
                  style="font-weight: 700"
                  href="mailto:adelgadoolson@zaawink.com"
                  target="_blank"
                >
                  EMAIL
                </v-btn>
                <v-btn
                  rounded
                  large
                  outlined
                  class="px-5 px-sm-15"
                  :width="btnWidth"
                  style="font-weight: 700"
                  href="https://www.linkedin.com/in/andreadelgadoolson/"
                  target="_blank"
                >
                  LINKEDIN
                </v-btn>
              </v-container>
            </v-col>
            <v-col cols="12" md="6" order-md="2" order="1">
              <v-img class="ma-sm-10" src="@/assets/AndreaPhoto.png" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

  // Used for title naming
  mounted() {  
    document.title = "Our Team";  
  },  
  computed: {
    btnWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return '35%';
        case "sm":
          return '45%';
        case "md":
          return '45%';
        case "lg":
          return '45%';
        case "xl":
          return '45%';
        default:
          return '45%';
      }
    },
 
  },
};
</script>

<style scoped>
.shape {
  position: relative;
  overflow: hidden;
  height: 340px;
  color: linear-gradient(
    90deg,
    rgba(24, 155, 158, 1) 0%,
    rgba(14, 92, 93, 1) 44%,
    rgba(1, 3, 3, 1) 95%,
    rgba(1, 3, 3, 1) 100%
  );
}
.shape::before {
  border-radius: 46%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(24, 155, 158, 1) 0%,
    rgba(14, 92, 93, 1) 44%,
    rgba(1, 3, 3, 1) 95%,
    rgba(1, 3, 3, 1) 100%
  );
  right: -200px;
  left: -200px;
  top: -200px;
  content: "";
  bottom: 0;
  background-clip: border-box;
}
.teamBackground {
  position: absolute;
  top: 0;
  background: transparent;
  width: 100%;
  height: 426px;
  object-fit: cover;
}
.center {
  display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
}
.teamHeader {
  color: white;
  font-size: 56px;
  font-weight: 700;
  
}
.teamText {
  color: black;
  font-size: 41px;
  font-weight: 700;
}
.teamSubText {
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}
.v-btn--outlined {
  border: 3px solid #158f92;
}
@media (max-width: 450px) {
.team-card {
  margin: 0px !important;
}
}
/* for phone view */
/* Vuetify XS breakpoint */
@media (max-width: 600px) {
  .hidden-large {
    display: inline !important;
  }
  .teamSubText {
    color: black;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
}
@media (min-width: 600px) {
  .hidden-large {
    display: none !important;
  }
}
/* Vueitfy SM breakpoint */
@media (min-width: 600px) and (max-width: 960px) {
  .teamSubText {
    line-height: 29px;
  }
}
/* Vuetify MD breakpoint */
@media (min-width: 960px) and (max-width: 1264px) {
  .teamSubText {
    font-size: 18px;
    line-height: 27px;
  }
}
/* Vuetify LG breakpoint */
@media (min-width: 1264px) and (max-width: 1904px) {
  .teamSubText {
    font-size: 20px;
    line-height: 30px;
  }
}
/* Vuetify XL breakpoint */
@media (min-width: 1904px) {
  
}
</style>